import { Box, Button, Typography } from "@mui/material"
import accessIcon from '../assets/icons/access.png';
import certificateIcon from '../assets/icons/certificate.png';
import challengeIcon from '../assets/icons/challenge.png';
import supportIcon from '../assets/icons/support.png';

const SecondSection = () => {
    return <Box padding="50px 0px" flexWrap="wrap" display="flex" flexDirection="row" alignItems="flex-start" justifyContent="center" gap="100px">
        <Box gap="10px" display="flex" flexDirection="column" alignItems="center">
            <img src={challengeIcon} style={{ width: "75px" }} alt="Desafios reais" />
            <Typography fontSize={16} sx={{ fontFamily: "'Poppins', sans-serif" }} fontWeight={600} lineHeight="110%" color="#fff" textAlign="center">Aprenda com <br />problemas reais</Typography>
        </Box>
        <Box gap="10px" display="flex" flexDirection="column" alignItems="center">
            <img src={accessIcon} style={{ width: "75px" }} alt="Accesso Imediato" />
            <Typography fontSize={16} sx={{ fontFamily: "'Poppins', sans-serif" }} fontWeight={600} lineHeight="110%" color="#fff" textAlign="center">Accesso Imediato</Typography>
        </Box>
        <Box gap="10px" display="flex" flexDirection="column" alignItems="center">
            <img src={supportIcon} style={{ width: "75px" }} alt="Suporte" />
            <Typography fontSize={16} sx={{ fontFamily: "'Poppins', sans-serif" }} fontWeight={600} lineHeight="110%" color="#fff" textAlign="center">Suporte Online</Typography>
        </Box>
        <Box gap="10px" display="flex" flexDirection="column" alignItems="center">
            <img src={certificateIcon} alt="Certificado" style={{ width: "75px" }} />
            <Typography fontSize={16} sx={{ fontFamily: "'Poppins', sans-serif" }} fontWeight={600} lineHeight="110%" color="#fff" textAlign="center">Certificado de<br /> Conclusão</Typography>
        </Box>
    </Box>
}

export { SecondSection }