import { Box, Button, Typography } from "@mui/material"
import { Colors, HighLight } from "../App"
import Bruno from '../assets/bruno.jpg';
const SixthSection = () => {
    return <Box width="100%" padding="50px 0px" display="flex" alignItems="center" justifyContent="center">
        <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center" alignItems="flex-start" gap="30px" sx={{}}>
            <Box sx={{
                maxWidth: "30%",
                '@media (max-width: 1200px)': {
                    maxWidth: "60%"
                },
                '@media (max-width: 800px)': {
                    maxWidth: "95%"
                },
            }} display="flex" flexDirection="column" alignItems="center" gap="20px">
                <Typography textAlign="center" fontSize={36} sx={{ fontFamily: "'Poppins', sans-serif" }} lineHeight="110%" fontWeight={600} color="#fff">Com quem você vai <HighLight value="aprender" /></Typography>
                <Typography textAlign="justify" fontSize={16} sx={{ fontFamily: "'Poppins', sans-serif" }} lineHeight="110%" fontWeight={400} color="#fff">Bruno Adriano será seu professor, atualmente Head de tecnologia, com
                    mais de 14 anos de experiência em programação,
                    começou aos 12 anos de idade no universo da programação e
                    desde então veio acumulando experiência em
                    diversas tecnologias e linguagens de programação.<br /><br /> Já
                    atuou em startups, empresas de grande porte,
                    como freelancer, construindo plataformas SaaS e ministrando aulas de programação.<br /><br />
                    Sua missão hoje é transmitir parte
                    desse conhecimento adquirido ao longo dos anos, ajudando todos aqueles que desejam ingressar na área ou expandir seus conhecimentos.</Typography>
            </Box>
            <img src={Bruno} style={{ maxWidth: "320px", borderRadius: "5px", border: "1px dashed rgb(140,82,255)", }} alt="Bruno Adriano" />
        </Box>
    </Box>
}

export {
    SixthSection
}