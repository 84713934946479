import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material"
import { HighLight } from "../App"
import mysqlIcon from '../assets/icons/mysql.png';
import awsIcon from '../assets/icons/aws.png';
import bottleneckIcon from '../assets/icons/bottleneck.png';
import solveproblemIcon from '../assets/icons/solve_problem.png';
import sqlIcon from '../assets/icons/sql.png';
import thinkIcon from '../assets/icons/think.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const CardWithIcon = ({ icon, title, description }: { icon: any, title: string, description: string }) => {
    return <Box maxWidth="100px" gap="10px" display="flex" flexDirection="column" alignItems="center">
        <img src={icon} style={{ width: "75px" }} alt={description} />
        <Typography fontSize={14} sx={{ fontFamily: "'Poppins', sans-serif" }} fontWeight={400} lineHeight="120%" color="#fff" textAlign="center">{title}</Typography>
    </Box>
}

const AccordionItem = ({ title, description }: { title: string, description: any }) => {
    return <Accordion sx={{
        width: "40%",
        '@media (max-width: 1200px)': {
            width: "60%"
        },
        '@media (max-width: 800px)': {
            width: "95%"
        },
    }}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography fontSize={16} fontWeight={400} sx={{ fontFamily: "'Poppins', sans-serif" }}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography fontSize={14} fontWeight={400} sx={{ fontFamily: "'Poppins', sans-serif" }}>
                {description}
            </Typography>
        </AccordionDetails>
    </Accordion>
}

const FourthSection = () => {
    return <Box width="100%" padding="50px 0px" sx={{ color: "#fff" }} flexDirection="column" display="flex" alignItems="center" justifyContent="center">
        <Box display="flex" flexDirection="column" alignItems="center" gap="30px">
            <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center" alignItems="center" gap="30px" sx={{}}>
                <Box display="flex" flexDirection="column" alignItems="center" gap="20px">
                    <Box display="flex" flexDirection="row" alignItems="center" gap="10px">
                        <Typography textAlign="center" fontSize={36} sx={{ fontFamily: "'Poppins', sans-serif" }} lineHeight="110%" fontWeight={600} color="#fff">O que você vai <HighLight value="aprender" /></Typography>
                    </Box>
                    <Box padding="50px 0px" display="flex" flexWrap="wrap" flexDirection="row" alignItems="flex-start" justifyContent="center" gap="100px">
                        <CardWithIcon title="Configurar ambiente dev" description="Configurar ambiente MySQL" icon={mysqlIcon} />
                        <CardWithIcon title="SQL do básico ao avançado" description="SQL" icon={sqlIcon} />
                        <CardWithIcon title="Banco de dados na Cloud" description="AWS" icon={awsIcon} />
                        <CardWithIcon title="Analisar e identificar gargalos" description="Bottleneck" icon={bottleneckIcon} />
                        <CardWithIcon title="Avaliar soluções na aplicação e no banco" description="Avaliar" icon={thinkIcon} />
                        <CardWithIcon title="Desafios e exemplos práticos" description="Desafios" icon={solveproblemIcon} />
                    </Box>
                </Box>
            </Box>
        </Box>
        <AccordionItem title="Módulo 1 - Apresentação" description="Introdução ao curso e apresentação do instrutor." />
        <AccordionItem title="Módulo 2 - Teoria e nivelação" description="Aula teórica sobre banco de dados, como um banco de dados funciona e seus diferentes tipos" />
        <AccordionItem title="Módulo 3 - Preparando o ambiente de desenvolvimento" description="Instalação das ferramentas necessárias para o curso e configuração do ambiente de desenvolvimento com docker" />
        <AccordionItem title="Módulo 4 - SQL Básico" description="Comandos e operacções básicas de SQL aplicadas em um sistema de vendas" />
        <AccordionItem title="Módulo 5 - Tabelas e Relacionamentos" description="Criação de tabelas, relacionamentos e modelagem de bancos" />
        <AccordionItem title="Módulo 6 - SQL Avançado" description="Comandos e operações avançadas de SQL" />
        <AccordionItem title="Módulo 7 - Cloud" description="Banco de dados na cloud: preocupações, vantagens, custos e como usar" />
        <AccordionItem title="Módulo 8 - Observabilidade" description="Ferramentas de observabilidade, gargalos e problemas comuns, identificando e resolvendo" />
        <AccordionItem title="Módulo 9 - Desafios" description="Desafios práticos de modelagem, consultas avançadas e resolução de problemas" />
    </Box>
}

export { FourthSection }