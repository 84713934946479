import { Box, Button, Typography } from "@mui/material"
import { Colors, HighLight } from "../App"
import workIcon from '../assets/work.png';
import creditCards from '../assets/cartoes.png';
const Bullet = ({ description }: { description: string }) => {
    return <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
        <li />
        <Typography fontSize={16} textAlign="center" sx={{
            '@media (max-width: 1200px)': {
                textAlign: "left",
            }, fontFamily: "'Poppins', sans-serif"
        }} lineHeight="110%" fontWeight={400} color="#fff">
            {description}
        </Typography>
    </Box>
}

const PriceWithButton = () => {
    return <Box display="flex" flexDirection="column" alignItems="center" gap="10px">
        <Typography gap="5px" display="flex" flexDirection="row" alignItems="center" fontSize={14} sx={{ fontFamily: "'Poppins', sans-serif" }} lineHeight="110%" fontWeight={400} color="#fff">
            de <Typography sx={{ fontFamily: "'Poppins', sans-serif", color: "red", textDecoration: "line-through" }}>R$597,00</Typography> por:
        </Typography>
        <Box display="flex" alignItems="center" gap="3px" flexDirection="row">
            <Typography fontSize={22} sx={{ fontFamily: "'Poppins', sans-serif" }} lineHeight="110%" fontWeight={600} color="#fff">12x</Typography>
            <Typography fontSize={38} sx={{ fontFamily: "'Poppins', sans-serif" }} lineHeight="110%" fontWeight={600} color="#fff">R$19,66</Typography>
        </Box>
        <Typography fontSize={16} sx={{ fontFamily: "'Poppins', sans-serif" }} lineHeight="110%" fontWeight={400} color="#fff">ou R$197,00 à vista</Typography>
        <Button variant="contained" sx={{ backgroundColor: Colors.primary, border: `1px solid ${Colors.primary}`, width: "250px", "&:hover": { backgroundColor: Colors.primary, border: `1px solid white` } }}>INSCREVA-SE AGORA</Button>
        <img src={creditCards} alt="Credit Cards" />
    </Box>
}

const FifthSection = () => {
    return <Box width="100%" padding="50px 0px" sx={{ backgroundColor: "rgba(94,23,235,0.12)", color: "#fff" }} display="flex" alignItems="center" justifyContent="center">
        <Box display="flex" flexDirection="column" alignItems="center" gap="30px">
            <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center" alignItems="center" gap="30px" sx={{}}>
                <Box display="flex" flexDirection="column" alignItems="left" gap="20px">
                    <Typography textAlign="center" fontSize={36} sx={{ fontFamily: "'Poppins', sans-serif" }} lineHeight="110%" fontWeight={600} color="#fff">Ideal para <HighLight value="você" /> que:</Typography>
                    <Box display="flex" flexDirection="row" alignItems="center" gap="20px" sx={{
                        '@media (max-width: 1200px)': {
                            flexDirection: "column",
                            justifyContent: "center",
                        },
                    }}>
                        <img src={workIcon} alt="Working" />
                        <Box display="flex" flexDirection="column" alignItems="flex-start" gap="5px"
                            sx={{
                                '@media (max-width: 1200px)': {
                                    maxWidth: "95%",
                                },
                            }}>
                            <Bullet description="Está iniciando no mundo da programação" />
                            <Bullet description="Tem interesse em aprofundar seus conhecimentos sobre bancos de dados" />
                            <Bullet description="Busca adquirir experiência em projetos reais" />
                            <Bullet description="Já trabalha com MySQL e quer dominar contextos avançados" />
                            <Bullet description="Está em transição de carreira e almeja novas experiências" />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <PriceWithButton />
        </Box>
    </Box>
}

export {
    FifthSection,
    PriceWithButton
}