import { Box, Button, Typography } from "@mui/material"
import { Colors, HighLight } from "../App"
import sqlLogo from '../assets/logo-sql.png';
import checkbox from '../assets/icons/checkbox.png'
import { PriceWithButton } from "./fifthSection";
const BulletWithIcon = ({ description }: { description: string }) => {
    return <Box gap="5px" display="flex" flexDirection="row" alignItems="center">
        <img src={checkbox} alt="Checkbox" style={{ maxWidth: "18px" }} />
        <Typography textAlign="center" fontSize={16} sx={{ fontFamily: "'Poppins', sans-serif" }} lineHeight="110%" fontWeight={600} color="#fff">{description}</Typography>
    </Box>
}

const EighthSection = () => {
    return <Box width="100%" padding="50px 0px" display="flex" alignItems="center" justifyContent="center">
        <Box display="flex" flexDirection="column" flexWrap="wrap" justifyContent="center" alignItems="center" gap="30px" sx={{}}>
            <Typography textAlign="center" fontSize={36} sx={{ fontFamily: "'Poppins', sans-serif" }} lineHeight="110%" fontWeight={600} color="#fff">Ficou com alguma <HighLight value="dúvida" />?</Typography>
            <Button variant="contained" sx={{ backgroundColor: "#46CC6B", "&:hover": { backgroundColor: "#46CC6B" } }} onClick={() => window.open("https://wa.me/5511993581886?text=Ola!%20Gostaria%20de%20saber%20mais%20sobre%20o%20curso%20de%20MySQL.", "_blank")}>
                <img src="/images/whatsapp.png" alt="WhatsApp" style={{ cursor: "pointer", width: "35px" }} />
                <Typography paddingLeft="5px" textAlign="center" fontSize={16} sx={{ fontFamily: "'Poppins', sans-serif" }} lineHeight="110%" fontWeight={600} color="#fff">Fale com nosso time de especialistas</Typography>
            </Button>
            <Typography textAlign="center" fontSize={14} sx={{ fontFamily: "'Poppins', sans-serif" }} lineHeight="110%" fontWeight={400} color="#fff">&copy;2024 - Onda Dev. Todos os direitos reservados.</Typography>
            <Box display="flex" flexDirection="row" alignItems="center" gap="10px">
                <Button>
                    <Typography textAlign="center" fontSize={11} sx={{ fontFamily: "'Poppins', sans-serif" }} lineHeight="110%" fontWeight={600} color="#fff">Politica de privacidade</Typography>
                </Button>
                <Button>
                    <Typography textAlign="center" fontSize={11} sx={{ fontFamily: "'Poppins', sans-serif" }} lineHeight="110%" fontWeight={600} color="#fff">Termos de uso</Typography>
                </Button>
            </Box>
        </Box>
    </Box>
}
export {
    EighthSection
}

