import { Box } from '@mui/material';
import { FirstSection } from './components/firstSection';
import { SecondSection } from './components/secondSection';
import { ThirdSection } from './components/thirdSection';
import { FourthSection } from './components/fourthSection';
import { FifthSection } from './components/fifthSection';
import { SixthSection } from './components/sixthSection';
import { SeventhSection } from './components/seventhSection';
import { EighthSection } from './components/eighthSection';
import { NinthSection } from './components/ninthSection';

enum Colors {
  primary = "#5E17EB",
  secondary = "#000000",
  white = "#FFFFFF"
}

const HighLight = ({ value }: { value: string }) => {
  return (
    <span style={{ color: Colors.primary }}>{value}</span>
  );
}

function App() {
  return (
    <Box width="100%" gap="20px" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <FirstSection />
      <SecondSection />
      <ThirdSection />
      <FourthSection />
      <FifthSection />
      <SixthSection />
      <SeventhSection />
      <EighthSection />
      {/* <NinthSection /> */}
      <Box onClick={() => window.open("https://wa.me/5511993581886?text=Ola!%20Gostaria%20de%20saber%20mais%20sobre%20o%20curso%20de%20MySQL.", "_blank")} sx={{ position: "fixed", bottom: "20px", right: "20px" }}>
        <img src="/images/whatsapp.png" alt="WhatsApp" style={{ cursor: "pointer", width: "60px" }} />
      </Box>
    </Box >
  );
}

export default App;

export { Colors, HighLight };
