import { Box, Button, Typography } from "@mui/material"
import background from '../assets/bg.jpg';
import notebook from '../assets/notebook.png';
import { Colors, HighLight } from "../App";

const FirstSection = () => {
    return <Box width="100%" height="600px" sx={{ backgroundImage: `url(${background})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
        <Box width="100%" height="100%" sx={{ backgroundColor: "rgba(0,0,0,0.8)" }} display="flex" alignItems="center" justifyContent="center">
            <Box display="flex" flexDirection="column" alignItems="center" gap="30px">
                <Box
                    sx={{
                        '@media (max-width: 800px)': {
                            justifyContent: "center",
                            flexDirection: "column",
                        },
                    }}
                    display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" gap="30px">
                    <img src={notebook} alt="Notebook" style={{ width: "200px" }} />
                    <Box
                        sx={{
                            '@media (max-width: 800px)': {
                                alignItems: "center",
                                justifyContent: "center",
                            },
                        }}
                        display="flex" flexDirection="column" alignItems="flex-start" gap="20px">
                        <Box display="flex" flexDirection="row" alignItems="center" gap="10px">
                            <Typography fontSize={36} sx={{
                                fontFamily: "'Poppins', sans-serif",
                                '@media (max-width: 800px)': {
                                    textAlign: "center",
                                },
                            }} lineHeight="110%" fontWeight={600} color="#fff">SQL na Prática:<br /><HighLight value="Dominando Dados" /></Typography>
                        </Box>
                        <Typography
                            sx={{
                                fontFamily: "'Poppins', sans-serif",
                                '@media (max-width: 800px)': {
                                    textAlign: "center",
                                },
                            }}
                            fontSize={16} lineHeight="110%" fontWeight={400} color="#fff">Domine SQL resolvendo problemas reais.<br />Aprenda com exemplos práticos e<br /> prepare-se para os desafios do mercado de trabalho!</Typography>
                        <Button variant="contained" sx={{
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 400,
                            fontSize: "14px",
                            backgroundColor: Colors.primary, border: `1px solid ${Colors.primary}`, width: "250px", "&:hover": { backgroundColor: Colors.primary, border: `1px solid white` }
                        }}>INSCREVA-SE AGORA</Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    </Box>
}

export { FirstSection }