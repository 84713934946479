import { Box, Typography } from "@mui/material"
import { HighLight } from "../App"
import { PriceWithButton } from "./fifthSection"

const ThirdSection = () => {
    return <Box width="100%" padding="50px 0px" sx={{ backgroundColor: "rgba(94,23,235,0.12)", color: "#fff" }} display="flex" alignItems="center" justifyContent="center">
        <Box display="flex" flexDirection="column" alignItems="center" gap="30px">
            <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center" alignItems="center" gap="30px" sx={{}}>
                <Box display="flex" maxWidth="40%"
                    sx={{
                        '@media (max-width: 1200px)': {
                            maxWidth: "60%"
                        },
                        '@media (max-width: 800px)': {
                            maxWidth: "95%"
                        },
                    }}
                    flexDirection="column" alignItems="center" gap="20px">
                    <Box display="flex" flexDirection="row" alignItems="center" gap="10px">
                        <Typography textAlign="center" fontSize={36} sx={{ fontFamily: "'Poppins', sans-serif" }} lineHeight="110%" fontWeight={600} color="#fff">Aprenda com problemas vivenciados <HighLight value="diariamente" /> nas <HighLight value="empresas" /></Typography>
                    </Box>
                    <Typography fontSize={16} textAlign="center" sx={{ fontFamily: "'Poppins', sans-serif" }} lineHeight="110%" fontWeight={400} color="#fff">Chega de exemplos e aulas que não mostram pelo que você vai realmente ser cobrado na hora de trabalhar. Este curso foi elaborado para trazer problemas reais já vivenciados e torna-lo um ninja quando o assunto for bancos de dados MySQL. Compilamos nesse curso a experiência de mais de 20 anos trabalhando na área, com exemplos práticos para modelar bancos, construir queries das mais simples as mais complexas, identificar e solucionar gargalos, avaliar cenários para resolução de problemas, bancos de dados na cloud e muito mais. </Typography>
                </Box>
            </Box>
        </Box>
    </Box>
}

export { ThirdSection }