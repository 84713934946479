import { Box, Button, Typography } from "@mui/material"
import { Colors, HighLight } from "../App"
import sqlLogo from '../assets/logo-sql.png';
import checkbox from '../assets/icons/checkbox.png'
import { PriceWithButton } from "./fifthSection";
const BulletWithIcon = ({ description }: { description: string }) => {
    return <Box gap="5px" display="flex" flexDirection="row" alignItems="center">
        <img src={checkbox} alt="Checkbox" style={{ maxWidth: "18px" }} />
        <Typography textAlign="center" fontSize={16} sx={{ fontFamily: "'Poppins', sans-serif" }} lineHeight="110%" fontWeight={600} color="#fff">{description}</Typography>
    </Box>
}

const SeventhSection = () => {
    return <Box width="100%" padding="50px 0px" display="flex" alignItems="center" justifyContent="center" sx={{ backgroundColor: "rgba(94,23,235,0.12)", }}>
        <Box display="flex" flexDirection="column" flexWrap="wrap" justifyContent="center" alignItems="center" gap="30px" sx={{}}>
            <Typography textAlign="center" fontSize={36} sx={{ fontFamily: "'Poppins', sans-serif" }} lineHeight="110%" fontWeight={600} color="#fff">Inscreva-se agora mesmo</Typography>
            <Box padding="40px" gap="20px" display="flex" flexDirection="column" alignItems="center" sx={{ border: "2px solid rgb(140,82,255)", boxShadow: 'rgba(255, 255, 255, 0.16) 0px 10px 36px 0px, rgba(255, 255, 255, 0.06) 0px 0px 0px 1px', borderRadius: "10px", backgroundColor: "black" }}>
                <img src={sqlLogo} alt="SQL" style={{ maxWidth: "250px" }} />
                <Box display="flex" gap="15px" flexDirection="column" alignItems="flex-start">
                    <BulletWithIcon description="Curso completo" />
                    <BulletWithIcon description="7 dias de garantia" />
                    <BulletWithIcon description="Certificado de conclusão" />
                </Box>
                <PriceWithButton />
            </Box>
        </Box>
    </Box>
}
export {
    SeventhSection
}

